<template>
  <v-dialog
    v-model="makesEditorDialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Vehicle Make</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="makeDataLocal.name"
                :error-messages="errors.name"
                label="Make Name"
                dense
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="$emit('update:makes-editor-dialog', false)"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          :disabled="saving"
          @click="saveMake"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { watch, ref } from '@vue/composition-api'
import axios from '@axios'

export default {
  props: {
    makesEditorDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    makeData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const saving = ref(false)
    const errors = ref([])
    const makeDataLocal = ref({})
    makeDataLocal.value = JSON.parse(JSON.stringify(props.makeData))
    const saveMake = () => {
      saving.value = true
      const { name, id } = makeDataLocal.value
      if (makeDataLocal.value.id) {
        // update
        axios
          .put(`/makes/${makeDataLocal.value.id}`, { name, id })
          .then(_ => {
            saving.value = false
            emit('saveMake')
            emit('update:makes-editor-dialog', false)
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      } else {
        // new
        axios
          .post('/makes', { name })
          .then(_ => {
            saving.value = false
            emit('saveMake')
            emit('update:makes-editor-dialog', false)
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      }
    }

    watch(
      () => props.makesEditorDialog,
      () => {
        makeDataLocal.value = JSON.parse(JSON.stringify(props.makeData))
        errors.value = []
      },
    )

    return {
      // variables
      errors,
      makeDataLocal,
      saving,

      // functions
      saveMake,
    }
  },
}
</script>
