<template>
  <v-card>
    <v-card-title>Makes</v-card-title>
    <v-card-text>
      <v-fade-transition>
        <v-alert
          v-show="actionAlert"
          border="left"
          color="success"
          dark
          text
          dismissible
        >
          {{ actionAlertMessage }}
        </v-alert>
      </v-fade-transition>
    </v-card-text>
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        v-model="searchQuery"
        placeholder="Search"
        outlined
        hide-details
        dense
        clearable
        class="user-search me-3 mb-4"
        @keyup.enter="fetchMakes"
        @click:clear="clearSearch"
      >
      </v-text-field>

      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">
        <v-btn
          v-if="$store.getters['auth/can'](['create vehicles'])"
          color="primary"
          class="mb-4 me-3"
          @click="makeData = {};makesEditorDialog = !makesEditorDialog"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Make</span>
        </v-btn>

        <!-- <v-btn
          color="secondary"
          outlined
          class="mb-4"
          disabled
        >
          <v-icon
            size="17"
            class="me-1"
          >
            {{ icons.mdiExportVariant }}
          </v-icon>
          <span>Export</span>
        </v-btn> -->
      </div>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="makes"
        :options.sync="options"
        :server-items-length="totalMakes"
        :footer-props="footerProps"
        @page-count="pageCount = $event"
      >
        <!-- name -->
        <template #[`item.name`]="{ item }">
          <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.name }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="$store.getters['auth/can'](['edit vehicles'])"
                @click="editItem(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="$store.getters['auth/can'](['delete vehicles'])"
                @click="deleteItemConfirm(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>
    <makes-editor
      :makes-editor-dialog.sync="makesEditorDialog"
      :make-data="makeData"
      @saveMake="saveMake"
    ></makes-editor>
    <!-- dialog -->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="dialogDelete = !dialogDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItem"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiExportVariant,
  mdiPencilOutline,
  mdiDeleteOutline,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import axios from '@axios'
import MakesEditor from './MakesEditor.vue'
import useMakes from './useMakes'
import useUIResolver from '../useUIResolver'

const defaultAlertMessage = 'Make successfully saved.'

export default {
  components: {
    MakesEditor,
  },
  setup() {
    const makeData = ref({})
    const actionAlert = ref(false)
    const actionAlertMessage = ref('')
    const makesEditorDialog = ref(false)
    const searchQuery = ref('')
    const totalMakes = ref(0)
    const footerProps = ref({
      itemsPerPageOptions: [15, 50, 100],
    })
    const options = ref({
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 15,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ['name'],
      sortDesc: [],
    })
    const dialogDelete = ref(false)

    onMounted(() => {
      actionAlertMessage.value = defaultAlertMessage
    })

    const { makes, getMakes } = useMakes()
    const { makeTableHeaders } = useUIResolver()

    const fetchMakes = () => {
      getMakes({ ...options.value, ...{ searchQuery: searchQuery.value } }).then(response => {
        const { total } = response.data
        totalMakes.value = total
        options.value.itemsPerPage = response.data.per_page
      })
    }

    const clearSearch = () => {
      searchQuery.value = null
      fetchMakes()
    }

    const saveMake = () => {
      actionAlertMessage.value = defaultAlertMessage
      actionAlert.value = true
      fetchMakes()
    }

    const editItem = item => {
      // show makes-editor dialog
      makesEditorDialog.value = true

      // assign the select make
      makeData.value = item
    }

    const deleteItem = () => {
      axios.delete(`/makes/${makeData.value.id}`).then(response => {
        actionAlertMessage.value = 'Make successfully deleted.'
        actionAlert.value = true

        // hide confirm alert
        dialogDelete.value = false
        fetchMakes()
      })
    }

    const deleteItemConfirm = item => {
      makeData.value = item
      dialogDelete.value = true
    }

    watch(options, fetchMakes)

    watch(actionAlert, val => {
      if (val) {
        setTimeout(() => {
          actionAlert.value = false
        }, 3000)
      }
    })

    return {
      dialogDelete,
      actionAlert,
      actionAlertMessage,
      makeData,
      headers: makeTableHeaders(),
      searchQuery,

      totalMakes,
      footerProps,
      options,

      // useMakes
      makes,
      getMakes,

      // functions
      clearSearch,
      makesEditorDialog,
      saveMake,
      fetchMakes,

      editItem,
      deleteItem,
      deleteItemConfirm,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPlus,
        mdiExportVariant,
        mdiPencilOutline,
        mdiDeleteOutline,
      },
    }
  },
}
</script>
